.NotificApproved{
    background-color: #f2ffee;
    border-radius: 5px;
    border-color: #f2ffee;
}
.NotificRejected{
    background-color: #fff1ee;
    border-radius: 5px;
    border-color: #fff1ee;
}
.NotificDefault{
    background-color: #f2f2f2;
    border-radius: 5px;
    border-color: #f2f2f2;
}
.NotificStatus{
    background-color: #eef7ff;
    border-radius: 5px;
    border-color: #eef7ff;
}
.NotificApproved .notiText,.NotificRejected .notiText,.NotificStatus .notiText,.NotificDefault .notiText{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}
.NotificApproved .notiDate,.NotificRejected .notiDate,.NotificStatus .notiDate,.NotificDefault .notiDate{
    font-size: 12px;
    font-weight: 100;
    text-align: right;
}
